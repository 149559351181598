.title {
  &__subtitle {
    position: absolute;
    left: 0;
    bottom: -10;
    font-size: 0.6rem;
    color: rgb(88, 88, 88);
  }
}

.title-font {
  font-family: $title;
  position: relative;

  &--small {
    font-size: 1.5rem;
  }

  &--medium {
    font-size: 1.8rem;
  }

  &--large {
    font-size: 2.2rem;
  }
}

.text-font {
  font-family: $text;
}

.purple-font {
  color: #b01af7;
}

.orange-font {
  color: orange;
}

.blue-font {
  color: blue;
}

.gray-font {
  color: gray;
}

.code-font {
  font-family: monospace;
}

a {
  text-decoration: none;
  color: white;
}

.text--loading {
  color: white;
  position: relative;

  &:before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    color: red;
    overflow: hidden;
    animation: loading 1s linear infinite;
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
