.menu {
  &__cover {
    z-index: 200;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.92);
    padding: 40px 30px 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--no-opacity {
      background-color: black;
    }

    &--higher-z-index {
      z-index: 500;
    }

    &-enter {
      transform: translateX(400px);
    }

    &-enter-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }

    &-exit {
      transform: translateX(0);
    }

    &-exit-active {
      transform: translateX(400px);
      transition: transform 300ms ease-in-out;
    }
  }

  &__label {
    flex: 1;
    width: 100%;
    font-family: $title;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    position: relative;

    &__sub-label {
      position: absolute;
      width: 250px;
      font-size: 0.7rem;
      color: gray;
      transform: translateY(41px) translateX(49px);
    }
  }

  &__dashboard {
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-row {
      flex: 1;
      width: 100%;
      margin: 3px;
      position: relative;
      display: flex;
      justify-content: space-between;
      column-gap: 6px;
      align-items: center;

      &--flex-column {
        flex-direction: column;
        row-gap: 6px;
      }

      &-box {
        flex: 1;
        height: 100%;
        background-color: #b01af7;
        border-radius: 15px;
        font-family: $title;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 300ms linear;

        &:hover {
          border-radius: 0;
          background-color: #b11af7b0;
          cursor: pointer;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      &-box--dark {
        &:hover {
          background-color: rgb(31, 31, 31);
        }
      }

      &-box--light {
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }

    &-row--thick {
      flex: 2;
      display: block;
      width: 100%;
      margin: 3px;
      padding: 20px;
      background-color: black;
      border-radius: 15px;
      border: 1px solid white;
      overflow: auto;
      overflow-wrap: break-word;
    }
  }
}

.vision-mode__cover {
  position: relative;

  &::before {
    position: absolute;
    content: attr(data-label);
    width: 100%;
    height: 100%;
    background-color: #b01af7;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms linear;
  }

  &:hover::before {
    transform: scale(4);
    opacity: 0;
  }
}
