* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0.3vw;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(83, 83, 83, 0.548);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background-color: white;
}

body {
  color: white;
  font-family: $text;
  background-color: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-header {
  display: none;

  @mixin atMedium {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
