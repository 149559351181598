.icon {
  color: rgb(97, 97, 97);
  transition: all 100ms ease-in-out;

  &:hover {
    color: white;
    transform: scale(1.1);
    cursor: pointer;
  }

  &:active {
    transform: scale(0.85);
  }

  &--position-absolute {
    position: absolute;
  }

  &--higher-z-index {
    z-index: 300;
  }

  &--error {
    color: red;

    &:hover {
      color: red;
      cursor: auto;
    }
  }

  &--checked {
    color: greenyellow;
  }

  &-copy {
    &--active {
      color: lightseagreen;

      &:hover {
        cursor: pointer;
        color: lightseagreen;
      }
    }
  }

  &--floating {
    animation: floating 1000ms infinite linear;

    &:hover {
      color: green;
    }
  }

  &__menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &-home {
    width: 30px;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }
}

.copied-popup {
  position: relative;

  &::before {
    content: "Copied!";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15;
    left: 60;
    width: 70px;
    height: 30px;
    font-size: 1.2rem;
    color: lightseagreen;
  }
}

@keyframes floating {
  0% {
    transform: translateY(4px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(4px);
  }
}
