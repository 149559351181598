.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;

  &--column {
    flex-direction: column;
  }

  &--space-between {
    justify-content: space-between;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.position-absolute {
  position: absolute;
}

.display-none {
  display: none;
}

.font {
  &--russo-one {
    font-family: $title;
  }

  &--michroma {
    font-family: $text;
  }
}

.overflow {
  &--scroll {
    overflow: scroll;
  }
}
