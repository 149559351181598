/* INPUT ELEMENT */
input {
  position: absolute;
  width: 100%;
  height: 80%;
  border: 1px solid white;
  border-radius: 15px;
  background: black;
  padding: 0px 15px 0px 15px;
  font-family: $text;
  font-size: 1.5rem;
  color: white;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 10px 0px rgb(105, 105, 105);
  }
}

.input-placeholder {
  position: absolute;
  top: 25px;
  left: 22px;
  display: inline-block;
  transition: 0.5s;
  pointer-events: none;
  color: rgb(105, 105, 105);
}

input:focus ~ span,
input:valid ~ span {
  transform: translateX(250px) translateY(-36px);
  font-size: 0.6rem;
  color: white;
}

/* INPUT CLASS */
.input {
  &-container {
    width: 370px;
    height: 76px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-purple {
    border: 1px solid #b01af7;

    &:hover,
    &:focus {
      border: 1px solid #d57aff;
    }
  }

  &-white {
    border: 1px solid rgb(126, 126, 126);
    transition: all 200ms ease;

    &:hover,
    &:focus {
      border: 1px solid white;
    }
  }

  &-validation {
    position: absolute;
    bottom: -6;
    left: 20;
    font-size: 0.5rem;

    &--error {
      color: red;
      bottom: -23;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--success {
      color: greenyellow;
      bottom: -23;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &--position-off {
    position: relative;
  }

  &--focus-red {
    border-width: 2px;

    &:focus {
      border-color: red;
    }
  }

  &--focus-green {
    border-color: green;
    border-width: 2px;

    &:focus {
      border-color: green;
      border-width: 2px;
    }
  }
}

.eth-txBuilder-input {
  position: relative;
  border-radius: initial;
  padding: 0 0 0 12px;
  border: none;
  height: 100%;
  background: none;
  caret-color: #d57bff;
  color: #8746a6;

  &:focus {
    background-color: #bd00ff;
    color: white;
  }
}
