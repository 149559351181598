.interface {
  &__block {
    width: 100%;
    flex: 1;
    margin: 1px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;

    &-cell {
      width: 100%;
      flex: 1;
      background-color: transparent;
      border-radius: 15px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &__annotation {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.5rem;

        &--orange {
          color: rgb(194, 107, 0);
        }

        &--blue {
          color: rgb(82, 82, 255);
        }
      }

      &--display-block {
        display: block;
        max-height: 157px;
        padding: 7px;
        overflow: auto;
        overflow-wrap: break-word;
        border-radius: 5px;
        position: relative;
        background-color: #2d2d2d;
      }

      &__footer {
        justify-content: space-between;
      }

      &--space-between {
        justify-content: space-between;
      }

      &--column-gap {
        column-gap: 8px;
      }

      &__description-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
      }

      &--thin {
        flex: 1;
        font-size: 1.3rem;
        padding-bottom: 10px;
      }

      &--thick {
        flex: 2;
        font-size: 0.6rem;
        color: darkgray;

        &--font-large {
          font-size: 1rem;
        }
      }

      &--appear {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -15;
          width: 400px;
          animation: appear-pulse 1s linear;
        }
      }

      &--appear-2 {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 400px;
          animation: appear-pulse 1s linear;
        }
      }
    }
  }
}

@keyframes appear-pulse {
  0% {
    background-color: rgba(255, 255, 255, 0.781);
  }

  33% {
    background-color: rgba(255, 255, 255, 0.171);
  }

  66% {
    background-color: rgba(255, 255, 255, 0.781);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.171);
  }
}
