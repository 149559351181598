.wallet-main {
  &__overlay {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 500px;
    padding: 0 14px 0 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
    align-items: center;

    &-enter {
      transform: translateX(400px);
    }

    &-enter-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }

    &-exit {
      transform: translateX(0);
    }

    &-exit-active {
      transform: translateX(400px);
      transition: transform 300ms ease-in-out;
    }
  }

  &__asset-display {
    position: relative;
    flex: 1;
    width: 100%;
    border-radius: 15px;
    padding-left: 15px;
    padding-top: 15px;
    overflow: hidden;
    transition: all 100ms ease-in-out;

    &--label {
      position: absolute;
    }

    &--bitcoin {
      background-color: rgba(255, 166, 0, 0.61);

      &-logo {
        position: absolute;
        transform: translateY(24px) translateX(167px);
      }

      &:hover {
        background-color: orange;
      }
    }

    &--ethereum {
      background-color: rgba(0, 0, 255, 0.61);

      &-logo {
        position: absolute;
        transform: translateY(14px) translateX(167px);
      }

      &:hover {
        background-color: blue;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

.snapshot {
  &__overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-enter {
      transform: translateX(-400px);
    }

    &-enter-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }

    &-exit {
      transform: translateX(0);
    }

    &-exit-active {
      transform: translateX(-400px);
      transition: transform 300ms ease-in-out;
    }
  }

  &__function-wrapper {
    width: 100%;
    margin-bottom: 5px;
    height: auto;
  }

  &__function-titlebar {
    border: 1px solid purple;
    border-radius: 15px;
    cursor: pointer;
    font-family: $title;
    font-size: 2rem;
    padding: 8px;
    color: rgb(185, 185, 185);

    &--orange {
      border-color: orange;
      background-color: transparent;
      transition: all 200ms ease-in-out;

      &:hover {
        background-color: rgba(255, 166, 0, 0.589);
      }

      &--active {
        color: white;
        background-color: rgba(255, 166, 0, 0.589);
      }
    }

    &--blue {
      border-color: blue;
      background-color: transparent;
      transition: all 200ms ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 255, 0.504);
      }

      &--active {
        color: white;
        background-color: rgba(0, 0, 255, 0.504);
      }
    }
  }

  &__function-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--display {
      height: 333px;
      max-height: 999px;
      overflow: hidden;
      transition: all 300ms ease-in-out;
    }

    &--hide {
      height: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 300ms ease-in-out;
    }

    &__row {
      flex: 1;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
