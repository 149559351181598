.container {
  width: 95vw;
  height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 30px;
  background-color: #101115;
  padding: 5px 14px 5px 14px;
  overflow: hidden;

  @mixin atSmall {
    width: 400px;
    height: 700px;
  }

  &:hover {
    box-shadow: 0 0 2px 2px white;
  }
}

.container__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;

  &-enter {
    transform: translateX(400px);
  }

  &-enter-active {
    transform: translateX(0);
    transition: all 300ms ease-in-out;
  }

  &-exit {
    transform: translateX(0);
  }

  &-exit-active {
    transform: translateX(-400px);
    transition: all 300ms ease-in-out;
  }
}
