/* BUTTON ELEMENT */
button {
  width: 100%;
  height: 80%;
  border: 1px solid white;
  border-radius: 15px;
  background: transparent;
  font-family: $text;
  font-size: 1.5rem;
  color: white;
  transition: all 100ms ease;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.96);
  }
}

/* BUTTON CLASS */
.button {
  &-purple {
    border: 1px solid #b01af7;
    background-color: #b11af783;

    &--pulsing {
      animation: purple-bg-pulse 2s linear infinite reverse;
    }

    &:hover {
      background-color: #b01af7;
    }
  }

  &-orange {
    position: relative;
    border: 1px solid orange;
    background-color: rgba(255, 166, 0, 0.5);

    &--pulsing {
      animation: orange-bg-pulse 2s linear infinite reverse;
    }

    &:hover {
      background-color: orange;
    }
  }

  &-blue {
    position: relative;
    border: 1px solid blue;
    background-color: rgba(0, 0, 255, 0.493);

    &--pulsing {
      animation: blue-bg-pulse 2s linear infinite reverse;
    }

    &:hover {
      background-color: blue;
    }
  }

  &-red {
    position: relative;
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.474);

    &:hover {
      background-color: red;
    }
  }

  &--active {
    background-color: white;
    color: black;
  }

  &--disabled {
    background-color: #4e246183;
    color: rgb(87, 87, 87);

    &:hover {
      background-color: #4e246183;
      color: rgb(87, 87, 87);
      cursor: not-allowed;
    }
  }

  &--smaller-font {
    font-size: 1.2rem;
  }
}

.capsule {
  font-size: 1rem;
  border: 1px solid #b01af7;

  &__progress-0 {
    &:hover {
      cursor: wait;
    }
  }

  &__progress-1 {
    display: none;
    background-color: #b11af736;
    &:hover {
      cursor: wait;
    }
  }

  &__progress-2 {
    display: none;
    background-color: #b11af775;
    &:hover {
      cursor: wait;
    }
  }

  &__progress-3 {
    display: none;
    background-color: #b11af7bd;
    &:hover {
      cursor: wait;
    }
  }

  &__progress-4 {
    display: none;
    background-color: #b01af7;
  }

  &--visible {
    display: block;
  }

  &:hover {
    box-shadow: 0 0 10px 0px rgb(105, 105, 105);
  }
}

.orange-capsule {
  font-size: 1rem;
  border: 1px solid orange;

  &__progress-1 {
    display: none;
    background-color: rgba(255, 166, 0, 0.201);

    &:hover {
      cursor: wait;
    }
  }

  &__progress-2 {
    display: none;
    background-color: rgba(255, 166, 0, 0.391);

    &:hover {
      cursor: wait;
    }
  }

  &__progress-3 {
    display: none;
    background-color: rgba(255, 166, 0, 0.653);
    &:hover {
      cursor: wait;
    }
  }

  &__progress-4 {
    display: none;
    background-color: orange;
    &:hover {
      cursor: wait;
    }
  }

  &__progress-done {
    display: none;
    background-color: none;
    color: rgba(255, 166, 0, 0.575);
    border-color: rgba(255, 166, 0, 0.24);
    &:hover {
      cursor: default;
    }
  }

  &--visible {
    display: block;
  }

  &:hover {
    box-shadow: 0 0 10px 0px rgb(105, 105, 105);
  }
}

.blue-capsule {
  font-size: 1rem;
  border: 1px solid blue;

  &__progress-1 {
    display: none;
    background-color: rgba(0, 0, 255, 0.26);

    &:hover {
      cursor: wait;
    }
  }

  &__progress-2 {
    display: none;
    background-color: rgba(0, 0, 255, 0.438);

    &:hover {
      cursor: wait;
    }
  }

  &__progress-3 {
    display: none;
    background-color: rgba(0, 0, 255, 0.678);
    &:hover {
      cursor: wait;
    }
  }

  &__progress-4 {
    display: none;
    background-color: blue;
    &:hover {
      cursor: wait;
    }
  }

  &__progress-done {
    display: none;
    background-color: none;
    color: rgba(0, 0, 255, 0.753);
    border-color: rgba(0, 0, 255, 0.26);
    &:hover {
      cursor: default;
    }
  }

  &--visible {
    display: block;
  }

  &:hover {
    box-shadow: 0 0 10px 0px rgb(105, 105, 105);
  }
}

.btc-logo {
  position: absolute;
  right: 13;
  bottom: 5;
}

.eth-logo {
  position: absolute;
  right: 13;
  bottom: 5;
}

.utxo-capsule {
  width: 296px;
  min-height: 50px;
  max-height: 50px;
  font-size: 0.7em;
  border: 1px solid greenyellow;
  background-color: rgba(0, 128, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
}

.put-capsule {
  min-width: 250px;
  max-width: 250px;
  min-height: 70px;
  max-height: 70px;
  font-size: 0.7em;
  background-color: rgba(255, 255, 255, 0.205);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.452);
  }

  &--selected {
    background-color: rgba(255, 255, 255, 0.452);
  }
}

/* ANIMATIONS */
@keyframes purple-bg-pulse {
  0% {
    background-color: #b01af7;
  }

  50% {
    background-color: #b11af750;
  }

  100% {
    background-color: #b01af7;
  }
}

@keyframes orange-bg-pulse {
  0% {
    background-color: orange;
  }

  50% {
    background-color: rgba(255, 166, 0, 0.336);
  }

  100% {
    background-color: orange;
  }
}

@keyframes blue-bg-pulse {
  0% {
    background-color: blue;
  }

  50% {
    background-color: rgba(0, 0, 255, 0.363);
  }

  100% {
    background-color: blue;
  }
}
