.tx-builder {
  &__overlay {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 534px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    &__outer {
      width: 370px;
      height: 75px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &-enter {
      transform: translateX(400px);
    }

    &-enter-active {
      transform: translateX(0);
      transition: all 300ms ease-in-out;
    }

    &-exit {
      transform: translateX(0);
    }

    &-exit-active {
      transform: translateX(-400px);
      transition: all 300ms ease-in-out;
    }
  }

  &__blueprint {
    width: 100%;
    height: 385px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #b11af734;
    border-top: 1px solid #b01af7;
    border-bottom: 1px solid #b01af7;

    &:hover {
      background-color: #b11af754;
    }

    &-dashboard {
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      &__puts-container {
        flex: 1;
        width: 398px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-row1 {
          flex: 1;
          width: 398px;
          padding-left: 45px;
          font-family: $title;
          color: rgb(255, 255, 255);
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
        }

        &-row2 {
          flex: 2;
          width: 398px;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 15px;
          overflow-x: scroll;
        }
      }

      &__input-field {
        flex: 1;
        width: 100%;
        min-height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 0.3px solid rgb(71, 71, 71);
        color: rgb(126, 126, 126);

        &-top {
          flex: 1;
          width: 100%;
          position: relative;
          padding-left: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 6px;
          font-family: $title;
        }

        &-bottom {
          flex: 1.5;
          width: 100%;
          max-height: 44.7px;
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &:hover {
          color: white;
        }
      }

      &__display-sig-overlay {
        position: absolute;
        width: 100%;
        height: 238px;
        background-color: mediumpurple;
        padding: 15px 25px 0px 25px;
        font-family: monospace;
        overflow-wrap: break-word;
        overflow-y: auto;
        font-size: 2rem;
        cursor: default;
      }
    }

    &-carousel {
      width: 100%;
      height: 300px;
      padding: 0 14px 0 14px;
      background-color: rgba(0, 128, 0, 0.342);
      border-top: 2px solid #bfff8c;
      border-bottom: 2px solid #bfff8c;
      display: flex;
      justify-content: flex-start;
      column-gap: 71px;
      align-items: center;
      position: relative;

      &:hover {
        background-color: rgba(0, 128, 0, 0.5);
      }

      &-enter {
        transform: translateY(-400px);
        opacity: 0;
      }

      &-enter-active {
        transform: translateY(0);
        opacity: 100;
        transition: all 300ms ease-in-out;
      }

      &-exit {
        transform: translateY(0);
        opacity: 100;
      }

      &-exit-active {
        transform: translateY(-400px);
        opacity: 0;
        transition: all 300ms ease-in-out;
      }

      &-container {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: flex-start;
        column-gap: 28px;
        align-items: center;
        transition: all 300ms ease-out;

        &--smaller {
          height: 200px;
        }
      }
    }

    &-carousel-confirm {
      width: 100%;
      height: 300px;
      padding: 0 14px 0 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &-enter {
        transform: translateY(400px);
        opacity: 0;
      }

      &-enter-active {
        transform: translateY(0);
        opacity: 100;
        transition: all 300ms ease-in-out;
      }

      &-exit {
        transform: translateY(0);
        opacity: 100;
      }

      &-exit-active {
        transform: translateY(400px);
        opacity: 0;
        transition: all 300ms ease-in-out;
      }

      &__container {
        width: 100%;
        height: 236px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        row-gap: 10px;
        translate: 0 27px;
      }
    }

    &-signing-section {
      &__container-top {
        flex: 1;
        width: 398px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 15px;
        overflow-x: scroll;
      }

      &__container-bottom {
        flex: 2;
        width: 398px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }

    &__review-overlay {
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &__column-gap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        align-items: center;
        min-height: 55px;
        margin: 15px 0 5px 0;
      }

      &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        overflow-wrap: anywhere;

        &-no-ul-format {
          width: 100%;
          padding: 0;
          list-style-type: none;
          margin: 0;
          margin-block: 0;
          margin-inline: 0;
          padding-inline: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        &-row {
          width: 100%;
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.75rem;

          &-label {
            color: #ff86ff;
            font-size: 0.8em;
          }
        }
      }
    }
  }
}

.utxo__display-card {
  min-width: 370px;
  position: relative;
  font-size: 0.7em;
  color: rgb(189, 189, 189);

  &--smaller {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    color: #646464;
    border: 1px solid #4b4b4bad;
    border-radius: 15px;
    background-color: #8080801c;
    min-height: 194px;
    max-height: 194px;
    font-size: 0.6em;
    padding-left: 35px;
    padding-top: 13px;
    overflow: hidden;

    &:hover {
      background-color: #a8a8a827;
    }

    &__button {
      position: absolute;
      top: 60px;
      left: 60px;
      width: 250px;
      height: 70px;
      background-color: #00800082;
      border: 1px solid greenyellow;
    }
  }

  &--signed-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-35px) translateY(-13px);
    color: white;
  }

  &:hover {
    cursor: pointer;
  }

  &--selected {
    color: white;
  }
}

.drag {
  width: 100%;
  height: 40px;
  background-color: #616161;
  border-radius: 6px;
  margin: 4px 0 0px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  cursor: grab;

  &:hover {
    background-color: gray;
  }
}

.drop {
  width: 100%;
  min-height: 40px;
  background-color: #915e00;
  border-radius: 6px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  cursor: default;

  &--correct {
    background-color: limegreen;
  }

  &--incorrect {
    background-color: red;
  }
}
