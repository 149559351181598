.modal {
  &__cover {
    z-index: 109;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: rgba(65, 65, 65, 0.507);

    &-enter {
      transform: translateY(700px);
    }

    &-enter-active {
      transform: translateY(0);
      transition: transform 400ms;
    }

    &-exit {
      transform: translateY(0);
    }

    &-exit-active {
      transform: translateY(700px);
      transition: transform 400ms;
    }
  }

  &__drop-down {
    z-index: 110;
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 415px;
    border-radius: 30px;
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    text-align: center;
    color: black;
    font-size: 0.7rem;

    &-enter {
      transform: translateY(-480px);
    }

    &-enter-active {
      transform: translateY(0);
      transition: transform 600ms cubic-bezier(1, 0, 1, 0);
    }

    &-exit {
      transform: translateY(0);
    }

    &-exit-active {
      transform: translateY(-480px);
      transition: transform 600ms cubic-bezier(1, 0, 1, 0);
    }

    &--title {
      font-size: 1rem;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-color: white;
      padding: 30px;
      font-size: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.modal-overlay {
  &-container {
    width: 276px;
    min-height: 140px;
    margin-top: 15px;
    position: relative;
    font-family: monospace;
    font-size: 1em;
    font-weight: bold;
    overflow-wrap: anywhere;
  }

  &-buttons-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
  }
}

@keyframes drop-down {
  0% {
    transform: translateY(-480px);
  }

  60% {
    transform: translateY(30px);
  }

  75% {
    transform: translateY(-20px);
  }

  90% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes cover-slide-up {
  0% {
    transform: translateY(700px);
  }

  100% {
    transform: translateY(0);
  }
}
