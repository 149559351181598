.special-effect {
  display: none;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(106, 106, 106);

  &--selected {
    background-color: white;
  }
}

.data-type-selector-container {
  width: 91px;
  height: 19px;
  font-family: $text;
  font-size: 0.6em;
  background-color: #747474;
  border-radius: 4px;
  overflow: hidden;

  &--box {
    width: 100%;
    height: 100%;
    flex: 1;
    text-align: center;
    padding: 2px;
    cursor: pointer;
  }
}

.box-selected {
  background-color: #eb00ff;
}

.hover {
  &--font-change {
    &:hover {
      color: #eb00ff;
      letter-spacing: 2px;
      transition: all 200ms linear;
    }
  }
}

.pulse-me {
  animation: pulse infinite 1.5s linear;
}

@keyframes pulse {
  0% {
    color: gray;
  }

  68% {
    color: gray;
  }

  70% {
    color: lightblue;
  }

  80% {
    color: gray;
  }

  90% {
    color: lightblue;
  }
}
