.wc-dashboard {
  position: relative;
  width: 90%;
  height: 363px;
  border: 5px solid white;
  border-radius: 15px;
  overflow: hidden;

  &__top {
    flex: 1;
    width: 100%;
    min-height: 136px;
    max-height: 136px;
    padding: 10px;
    text-align: center;
    font-family: $title;
  }

  &__bottom {
    position: relative;
    flex: 1.6;
    width: 100%;
    overflow-y: scroll;
    font-size: 0.8rem;
    text-align: left;
    min-height: 217px;
    line-height: 1.3rem;
    padding: 10px;
    color: darkgray;
    word-break: break-all;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 18;
      width: 90%;
      height: 1px;
      background-color: rgb(66, 66, 66);
    }
  }
}

.wc-inputs {
  width: 90%;
  height: 52px;
  margin-top: 10px;
}

.wc-logo {
  width: 90%;
  height: 70px;
}

.wc-modal {
  position: absolute;
  inset: 0;
  z-index: 1;
  text-align: center;
  font-family: $title;

  &-enter {
    transform: translateY(-364px);
  }

  &-enter-active {
    transform: translateY(0px);
    transition: transform 300ms;
  }

  &-exit {
    transform: translateY(0px);
  }

  &-exit-active {
    transform: translateY(-364px);
    transition: transform 300ms;
  }
}

.wc--error {
  position: absolute;
  inset: 0;
  background-color: red;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: $title;
  padding: 0 40px;
  text-align: left;

  &-enter {
    transform: scale(4);
    opacity: 0;
  }

  &-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: transform opacity 300ms ease-in;
  }

  &-exit {
    transform: scale(1);
    opacity: 1;
  }

  &-exit-active {
    transform: scale(4);
    opacity: 0;
    transition: transform opacity 300ms ease-in;
  }
}
